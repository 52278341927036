import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { Image } from '@unpic/react';
import {
  ButtonContainer, Container, Text, Title, Wrapper,
} from './style';
import ButtonComponent from '../../../partials/button/button';
import { NewsModel } from './api/interface/News';
import RichTextRenderer from '../../../services/richtextRenderer';

// TODO: Check what this does
async function incrementCounter() {
  fetch('https://blmsb5vrla.execute-api.eu-central-1.amazonaws.com/prod', {
    method: 'POST',
    mode: 'no-cors',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'x-api-key': `${process.env.REACT_APP_API_KEY_CONTACT}`,
    },
  });
}

interface NewsProps {
  data: NewsModel;
  buttonIdentifier: string;
}

function NewsComponent({ data, buttonIdentifier }: NewsProps) {
  const {
    newsContainer,
  } = data;
  return (
    <Wrapper>
      <Carousel
        autoPlay
        infiniteLoop
        interval={7000}
        swipeable
        showThumbs={false}
        className="carouselwrapper"
        showStatus={false}
      >
        {newsContainer?.map((news) => (
          <div key={news.newsText.title}>
            {news.newsPicture.newsImage
              && (
                <Image
                  background="auto"
                  priority
                  src={news.newsPicture.newsImage}
                  layout="fullWidth"
                  alt={news.newsPicture.imageAltText ? news.newsPicture.imageAltText : 'picture'}
                />
              )}
            <Container backgroundcolor={news.newsText.backgroundColor}>
              {news.newsText.title
                && (
                  <Title titlecolor={news.newsText.titleTextColor}>
                    {news.newsText.title}
                  </Title>
                )}
              {news.newsText.content
                && (
                  <Text textcolor={news.newsText.contentTextColor}>
                    <RichTextRenderer data={news.newsText.content} />
                  </Text>
                )}
              { news.newsText.hasButton && news.newsText.buttonText && news.newsText.buttonLink
                && (
                  <ButtonContainer>
                    <ButtonComponent
                      isNews
                      backgroundColor={news.newsText.buttonBackgroundColor}
                      fontColor={news.newsText.buttonTextColor}
                      text={news.newsText.buttonText}
                      refLink={news.newsText.buttonLink}
                      onClickIncrementCounter={() => incrementCounter()}
                      buttonOnHoverColor={news.newsText.buttonOnHoverColor}
                      buttonIdentifier={buttonIdentifier}
                    />
                  </ButtonContainer>
                )}
            </Container>
          </div>
        ))}
      </Carousel>
    </Wrapper>
  );
}

export default NewsComponent;
