import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Link } from './style';
import StickyProps from '../../interfaces/stickyProps';
import { handleAPICallAndRedirect } from '../../partials/button/utils';

export default function StickyComponent({
  href,
  text,
}: StickyProps) {
  const { pathname } = useLocation();

  const handleButtonTracking = async (event: React.MouseEvent) => {
    event.preventDefault();
    await handleAPICallAndRedirect({
      href, isDownload: false, pathname, buttonIdentifier: 'TerminStoerer', isNewTab: false,
    });
  };

  return (
    <Container>
      <Link
        href={href}
        onClick={(event) => handleButtonTracking(event)}
      >
        {text}
      </Link>
    </Container>
  );
}
