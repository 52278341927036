import axios from 'axios';

interface ResponseData {
  message: string;
}

const baseURL = `${process.env.REACT_APP_CLICK_TRACK_URL}/update-clicks`;
const authToken = process.env.REACT_APP_CLICK_TRACK_API_KEY;
const headers = {
  'Content-Type': 'application/json',
  Authorization: `${authToken}`,
};
export default async function clickTrackInfrastructure(path : string | undefined) {
  if (!baseURL || !authToken) throw new Error('No base URL or Auth Key provided');
  try {
    const response = await axios.post<ResponseData>(baseURL, {
      id: path,
    }, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}
