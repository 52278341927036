import React, { useState } from 'react';
import classNames from 'classnames';
import SidebarContent from './SidebarContent';
import { Wrapper } from './style';

function Sidebar(props: any) {
  const {
    open,
    wrapperClassName,
    options,
  } = props;
  const [active, setActiveTab] = useState<string[]>([]);
  const [list, setList] = useState<any>(options);
  const [prevList] = useState<string[]>([]);
  const { pathname } = window.location;
  const handleBackdropClick = () => {
    const { onToggle, onClose } = props;
    onToggle(false);
    onClose && onClose();
    setTimeout(() => {
      setActiveTab([]);
      setList(options);
    }, 500);
  };

  const handleTabClick = (tabData: any) => {
    if (tabData.disabled) {
      return;
    }
    if (tabData.children) {
      active.push(tabData.id);
      prevList.push(tabData.children);
      setActiveTab(active);
      setList(tabData.children);
    }
  };

  const handleBackClick = () => {
    active.pop();
    prevList.pop();
    if (active.length > 0) {
      setActiveTab([...active]);
      setList(prevList[prevList.length - 1]);
    } else {
      setActiveTab([]);
      setList(options);
    }
  };
  return (
    <Wrapper>
      <div id="react-sidebar" className="slidebar">
        {/* eslint-disable-next-line  */}
      <div className={classNames('sidebar-backdrop', { show: open })} onClick={() => handleBackdropClick()}/>
        <SidebarContent
          {...props}
          sidebarProps={{
            id: 'sidebar-parent',
            className: classNames('sidebar-main', {
              show: open,
              [wrapperClassName]: wrapperClassName,
            }),
          }}
          options={list}
          active={active[active.length - 1]}
          list={list}
          handleTabClick={handleTabClick}
          handleBackClick={handleBackClick}
        />
      </div>
    </Wrapper>
  );
}

export default Sidebar;
