import clickTrackInfrastructure from '../../services/clickTrackInfrastructure';
import sendGAARequest from '../../services/gaaButtonPush';
import { HandleAPICallAndRedirectParams, TrackButtonClickParams } from './interfaces';

export default async function trackButtonClick({
  data, isDownload, location, index,
}: TrackButtonClickParams) {
  const identifier = index?.split(' ').join('').replace(/[^\w\s]/gi, '');
  const baseStr = `${location}*${identifier}`;

  const downloadExec = data ? /(Download\S+)/.exec(data) : null;
  const underscoreExec = data ? /_(.+)/.exec(data) : null;
  const dashExec = data ? /-(.+)/.exec(data) : null;

  const str = isDownload && data
    ? `${baseStr};${downloadExec ? downloadExec[1] : underscoreExec ? underscoreExec[1] : dashExec ? dashExec[1] : data}`
    : data === undefined
      ? undefined
      : `${baseStr};${data}`;

  if (isDownload && data) {
    sendGAARequest(data);
  } else if (data === undefined) {
    return;
  }
  await clickTrackInfrastructure(str);
}

export const handleAPICallAndRedirect = async ({
  href, isDownload, pathname, buttonIdentifier, isNewTab,
}: HandleAPICallAndRedirectParams) => {
  try {
    await trackButtonClick({
      data: href, isDownload, location: pathname, index: buttonIdentifier,
    });
  } catch (error) {
    console.error('Error tracking button click:');
  } finally {
    window.open(href, isNewTab ? '_blank' : '_self');
  }
};
